import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// DO NOT REMOVE
// This will NOT work if you remove the "unnecessary" ones from react-scroll
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import I18n from 'i18n-js';

import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

// Components
import MaterialFormStatusInstructions from './MaterialFormStatusInstructions.js';
import MaterialFormStatusBar from './MaterialFormStatusBar.js';
import MaterialFormTabs from './MaterialFormTabs/MaterialFormTabs.js';

// Chakra
import { Box, Heading, Text, Spinner, Center, Divider } from '@chakra-ui/react';

class MaterialFormStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phases: [],
      activePhases: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.apartmentTemplates !== prevProps.apartmentTemplates) {
      // Map materialforms in case there's more than one
      const phases =
        this.props.apartmentTemplates &&
        this.props.apartmentTemplates[0]?.materialforms.map((form) => {
          return 1;
        });
      const activePhases = [...phases];
      this.setState({ phases, activePhases });
    }
  }

  // Set tab to active
  tabCallback = (phaseNumber, index) => {
    let activePhases = [...this.state.activePhases];
    activePhases[index] = phaseNumber;
    this.setState({ activePhases });
  };

  render() {
    const user = LocalStorageService.getUser();
    const userIsAdmin =
      user.roles.filter((role) => role.name === 'owner').length > 0;

    return (
      <>
        <Box
          display="flex"
          alignContent="space-between"
          flexWrap="wrap"
          pb={'1.25rem'}
        >
          <Heading flex={1}>
            {I18n.t('materialforms.linked_materialforms')}
          </Heading>
          <Divider />
          {this.props.loading && (
            <Center mt="12" width={'100vw'}>
              <Spinner size="xl" />
            </Center>
          )}
          <Box width={'100%'}>
            {this.props.apartmentTemplates &&
              this.props.apartmentTemplates[0].materialforms &&
              this.props.apartmentTemplates[0].materialforms.map(
                (form, index) => {
                  // Phase 1 = material choices, phase 2 = quote, phase 3 = room card
                  let phase = 1;
                  const submissionForm = this.props.submissionForms.find(
                    (submissionForm) =>
                      submissionForm.apartment_id === this.props.apartment.id &&
                      submissionForm.material_form_id === form.id
                  );
                  const quote = this.props.quotes.find(
                    (quote) =>
                      quote.material_form_submission_id === submissionForm.id
                  );

                  let status = '';
                  let formIsPending = false; // = waiting for customer's action
                  let quoteIsPending = false; // = waiting for admin action
                  if (submissionForm && !quote) {
                    if (submissionForm.status) {
                      status = submissionForm.status;
                      // Form is sent => enable the quote tab
                      if (status === 'sent') phase = 2;
                    } else {
                      status = 'open';
                    }
                    formIsPending = status === 'open';
                    quoteIsPending = status === 'sent';
                  } else if (quote && quote.status) {
                    // Quote exists => enable the quote tab
                    phase = 2;
                    if (quote.status === 'sent') {
                      status = 'open';
                    } else if (quote.status === 'pending') {
                      status = 'pending';
                      quoteIsPending = true;
                    } else if (quote.status === 'approved') {
                      // Quote is handled => enable the room card tab
                      status = 'accepted';
                      phase = 3;
                    }
                  }

                  let roomcarddeadline = null;
                  if (quote) {
                    roomcarddeadline = quote.room_card_approval_deadline;
                  }

                  const roomcarddeadlineHasPassed = roomcarddeadline
                  ? new Date(roomcarddeadline) < new Date(Date.now())
                    ? true
                    : false
                  : false;

                  const disabled = !quote
                    ? status === 'open'
                      ? false
                      : true
                    : true;
                  const apartment_deadline =
                    this.props.apartment.deadlines.find(
                      (x) => x.material_form_id === form.id
                    );
                  let deadline = null;
                  if (quote) {
                    deadline = quote.deadline; // is this even a working feature?
                  } else if (apartment_deadline) {
                    deadline = apartment_deadline.deadline;
                  }

                  const deadlineHasPassed = deadline
                    ? new Date(deadline) < new Date(Date.now())
                      ? true
                      : false
                    : false;

                  // activePhase = the selected phase & tab
                  let activePhase = this.state.activePhases[index];
                  // Update state in case more phases are enabled
                  if (phase !== this.state.phases[index]) {
                    let phases = [...this.state.phases];
                    phases[index] = phase;

                    activePhase = phase;
                    let activePhases = [...this.state.activePhases];
                    activePhases[index] = phase;

                    // Setting state in render method is not probably ok
                    this.setState({ phases, activePhases });
                  }

                  let phaseTitle = '';
                  switch (activePhase) {
                    case 1:
                      phaseTitle = I18n.t('materialforms.phases.materialform');
                      break;
                    case 2:
                      phaseTitle = I18n.t('materialforms.phases.quote');
                      break;
                    case 3:
                      phaseTitle = I18n.t('materialforms.phases.room-card');
                      break;
                    default:
                      phaseTitle = I18n.t('materialforms.phases.materialform');
                  }

                  // Non-DRY
                  let onGoingPhaseTitle = '';
                  switch (phase) {
                    case 1:
                      onGoingPhaseTitle = I18n.t(
                        'materialforms.phases.materialform'
                      );
                      break;
                    case 2:
                      onGoingPhaseTitle = I18n.t('materialforms.phases.quote');
                      break;
                    case 3:
                      onGoingPhaseTitle = I18n.t(
                        'materialforms.phases.room-card'
                      );
                      break;
                    default:
                      onGoingPhaseTitle = I18n.t(
                        'materialforms.phases.materialform'
                      );
                  }

                  let contentText = '';
                  let bg = '';
                  // Phase 1
                  if (formIsPending && !quoteIsPending) {
                    contentText = I18n.t('materialforms.statuses.pending', {
                      target: I18n.t('materialforms.pending-target.sending'),
                    });
                    bg = 'red';
                  } else if (!formIsPending && quoteIsPending) {
                    // Both material selections & quote tabs are enabled
                    if (activePhase === 1) {
                      contentText = I18n.t('materialforms.statuses.sent');
                      bg = 'green';
                    } else {
                      contentText = I18n.t('materialforms.statuses.pending', {
                        target: I18n.t('materialforms.pending-target.quote'),
                      });
                      bg = 'base.darkYellow';
                    }
                    // Phase 2
                  } else if (quote) {
                    if (quote.status === 'sent') {
                      if (activePhase === 1) {
                        contentText = I18n.t('materialforms.statuses.sent');
                        bg = 'green';
                      } else {
                        contentText = I18n.t('materialforms.statuses.pending', {
                          target: I18n.t(
                            'materialforms.pending-target.accepting'
                          ),
                        });
                        bg = 'red';
                      }
                      // Phase 3 - every tab is enabled
                    } else if (quote.status === 'approved') {
                      if (activePhase === 1) {
                        contentText = I18n.t('materialforms.statuses.sent');
                        bg = 'green';
                      } else if (activePhase === 2) {
                        contentText = I18n.t('materialforms.statuses.accepted');
                        bg = 'green';
                      } else if (activePhase === 3) {
                        if (!quote.custom_room_card) {
                          contentText = I18n.t(
                            'materialforms.statuses.pending',
                            {
                              target: I18n.t(
                                'materialforms.pending-target.room-card'
                              ),
                            }
                          );
                          bg = 'base.darkYellow';
                        } else if (
                          quote.custom_room_card &&
                          !quote.room_card_approved
                        ) {
                          contentText = I18n.t(
                            'materialforms.statuses.pending',
                            {
                              target: I18n.t(
                                'materialforms.pending-target.accepting'
                              ),
                            }
                          );
                          bg = 'red';
                        } else {
                          contentText = I18n.t('materialforms.statuses.ready');
                          bg = 'green';
                        }
                      }
                    }
                  }

                  return (
                    <>
                      <Box mb={5} textAlign={'end'}>
                        <Text fontSize={'sm'}>
                          {I18n.t('materialforms.active-phase')}:
                        </Text>
                        <Text fontSize={'sm'} fontWeight={'bold'}>
                          {`${this.state.phases[index]}.`} {onGoingPhaseTitle}
                        </Text>
                      </Box>
                      <Box
                        display={
                          this.state.targetMaterialform ? 'none' : 'initial'
                        }
                      >
                        <MaterialFormTabs
                          phases={this.state.phases[index]}
                          activePhase={this.state.activePhases[index]}
                          tabCallback={(phaseNumber) =>
                            this.tabCallback(phaseNumber, index)
                          }
                        />
                        <Box
                          pt={6}
                          borderStyle={'none solid solid solid'}
                          borderWidth={'1px'}
                          borderColor={'gray.inputBorder'}
                        >
                          <MaterialFormStatusBar
                            contentText={contentText}
                            form={form}
                            bg={bg}
                            deadline={deadline}
                            deadlineHasPassed={deadlineHasPassed}
                            roomcarddeadline={roomcarddeadline}
                            roomcarddeadlineHasPassed={roomcarddeadlineHasPassed}
                            disabled={disabled}
                            status={status}
                            quote={quote}
                            apartment={this.props.apartment}
                            activePhase={this.state.activePhases[index]}
                            isAdmin={userIsAdmin}
                            submissionForm={submissionForm}
                            activateCallback={(printable) =>
                              this.props.activateCallback(
                                form,
                                disabled,
                                submissionForm,
                                printable
                              )
                            }
                            openMaterialFormOfferCallback={() =>
                              this.props.openMaterialFormOfferCallback(
                                form,
                                submissionForm,
                                quote,
                              )
                            }
                          />
                          <MaterialFormStatusInstructions
                            activePhase={activePhase}
                            phaseTitle={phaseTitle}
                          />
                        </Box>
                      </Box>
                    </>
                  );
                }
              )}
          </Box>
        </Box>
      </>
    );
  }
}

export default withRouter(MaterialFormStatus);
