import React, { Component } from 'react';

class MaterialFormInstructionsIntro extends Component {
  render() {
    return (
      <div>
        <br></br>
        <p>
          <strong>
            1. Materiaalivalinnat tulee olla tehtyinä huoneistokatselmukseen
            mennessä. Huomioithan valintasivun alussa näkyvän viimeinen hyväksyntäpäivä -ajan,
            jolloin sähköinen valintalomake sulkeutuu. Sen jälkeen valintoja ei
            pääse enää itse muuttamaan. Huoneistokatselmuksen jälkeen kohteen
            asiakaspalveluinsinööri ottaa lomakkeen jatkokäsittelyyn
          </strong>
        </p>
        <br></br>
        <p>
          <strong>
            2. Täytä asuntoonne tulevat materiaalit valintanne mukaisesti. Jos
            et jotakin kohtaa osaa täyttää, asiakaspalveluinsinööri auttaa
            katselmuksen yhteydessä. Kaikki kohdat tarkistetaan huolellisesti
            ennen mahdollisten muutoshintojen laskemista.
          </strong>
        </p>
        <br className="onlyPrint"></br>
        <p className="onlyPrint">
          <strong className="onlyPrint">
            Jos kirjaatte valintanne paperilomakkeeseen, niin merkitkää joko
            ympyröimällä tai alleviivaamalla valitsemanne tuotteen tiedot.
          </strong>
        </p>
        <br className="onlyPrint"></br>
        <p>
          <strong>
            3. Muutostyötoiveet tarkennetaan katselmuksen yhteydessä.
          </strong>
        </p>
        <br></br>
        <p>
          <strong>
            4. Kaikista urakkaan kuulumattomista muutoksista ja lisätöistä on
            sovittava kirjallisesti osakasmuutoksista vastaavan pääurakoitsijan
            edustajan kanssa.
          </strong>
        </p>
        <br></br>
        <p>
          <strong>5. Katselmusajat löytyvät osakaskansiosta.</strong>
        </p>
        <br></br>
        <p>
          <strong>
            6. Jos urakkaan sisältyy antenni- ja telepisteiden uusinta,
            asennuspaikka kaapelireitteineen katsotaan yhdessä
            huoneistokatselmuksen aikana.
          </strong>
        </p>
        <br></br>
        <p>
          <strong>
            7. Asukkaan omat sähkö- tai lvi-kaluste hankinnat on täytettävä
            tämänhetkiset määräykset.
          </strong>
        </p>
        <br></br>
        <p>
          <strong>
            8. Mallikalusteet ja laatat ovat nähtävillä erillisessä
            mallikalustetilassa.
          </strong>
        </p>
        <br></br>
        <p>
          <strong>9. Kaikki hinnat sisältävät arvonlisäveron 24 %. Veroprosentti määräytyy kulloinkin voimassa olevan verokannan mukaisesti.</strong>
        </p>
      </div>
    );
  }
}

export default MaterialFormInstructionsIntro;
